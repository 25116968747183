class ConsentForm {
    
    constructor() {
        this.body = document.querySelector('body');
        this.form = document.getElementById("consentForm");
    }

    init() {
        if(this.form != null){
            this.addEventListeners();
        }
    }

    addEventListeners() {
        const self = this;


        var consentButtons = self.form.querySelectorAll(".js-postconsentform");
        Array.prototype.slice.call(consentButtons)
            .forEach(function (consentbutton) {
                consentbutton.addEventListener('click', self.fillField);
            });
    }

    fillField(event) {
        const button = event.currentTarget;
        const btnValue = button.value;
        Array.prototype.slice.call(document.querySelectorAll("input[name='button']"))
            .forEach(function (btn) {
                btn.value = btnValue;
            });
        self.form.submit();
    }
}


(() => {
    const consentForm = new ConsentForm();
    consentForm.init();
})();