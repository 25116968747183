class FormLocker {

    constructor() {
       
    }

    lockForm(target) {
        var button = target.querySelector("[type='submit']");
        var spinnerbutton = target.querySelector("[type='submit'] > span:first-child");
        spinnerbutton.classList.remove("d-none");
        button.classList.add("disabled");
    }
}

(() => {
    window.FormLocker = new FormLocker();
})();