import { Modal } from 'bootstrap';

class OnloadMod {

    constructor() {
        this.onloadModals = document.querySelectorAll(".modal[data-onload='true']");

    }

    init() {
        this.addEventListeners();
    }

    addEventListeners() {
        const self = this;
        if (self.onloadModals.length > 0) {
            Array.prototype.slice.call(self.onloadModals)
                .forEach(function (modal) {
                    if (!(modal === undefined || modal == null)) {
                        var autoModal = new Modal(modal, {})
                        if (autoModal != null && typeof (autoModal) != "undefined") {
                            self.openModal(autoModal);
                        }
                    }
                });
        }
    }

    openModal(modal) {
        modal.show()
    }
}

(() => {
    window.OnloadMod = new OnloadMod();
    window.OnloadMod.init();
})();
